import React from 'react';
import { Link } from 'react-router-dom';
import './LandingPage.css';

function LandingPage() {
  return (
    <div className="landing-page">
      <div className="landing-content">
        <h1>Welcome to the Traveller's Tavern Bot Dashboard</h1>
        <a href="/auth/twitch" className="twitch-button">Sign in with Twitch</a>
        <p>
          The Traveller's Tavern Bot is a customizable Twitch bot that provides
          stream management tools, viewer engagement features, and advanced
          customization options to elevate your streaming experience.
        </p>
      </div>
    </div>
  );
}

export default LandingPage;
