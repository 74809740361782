import React, { useState, useEffect } from 'react';
import './Dashboard.css';

function Dashboard() {
  const [activeTab, setActiveTab] = useState('home');
  const [user, setUser] = useState(null);
  const [settings, setSettings] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchUserData() {
      try {
        const response = await fetch('/api/user');
        if (response.ok) {
          const data = await response.json();
          setUser(data.user);
          setSettings(data.user.settings);
          setLoading(false);
        } else {
          console.error('Failed to load user data:', response.statusText);
          setLoading(false);
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
        setLoading(false);
      }
    }
    fetchUserData();
  }, []);

  const handleTabChange = (tab) => setActiveTab(tab);

  const toggleBotEnabled = async () => {
    const updatedBotEnabled = !settings.botEnabled;

    try {
      const response = await fetch('/api/settings/bot-enabled', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ botEnabled: updatedBotEnabled }),
      });

      const data = await response.json();
      if (response.ok) {
        setSettings((prev) => ({
          ...prev,
          botEnabled: updatedBotEnabled,
        }));
        console.log('Bot enabled state updated successfully.');
      } else {
        console.error('Error updating botEnabled state:', data.error);
        alert('Failed to update bot status.');
      }
    } catch (error) {
      console.error('Error updating botEnabled state:', error);
      alert('An unexpected error occurred while updating bot status.');
    }
  };

  const saveSettings = async () => {
    if (!settings) return;

    try {
      const response = await fetch('/api/settings', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(settings),
      });

      const data = await response.json();
      if (response.ok) {
        setSettings(data.settings);
        alert('Settings updated successfully!');
      } else {
        alert(`Error saving settings: ${data.error}`);
      }
    } catch (error) {
      alert('An unexpected error occurred while saving settings.');
    }
  };

  const startBot = async () => {
    try {
      const response = await fetch('/api/bot/start', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
      });

      if (!response.ok) {
        const error = await response.json();
        throw new Error(error.error || 'Unknown error occurred');
      }

      const data = await response.json();
      console.log('Bot started:', data.message);
    } catch (error) {
      console.error('Failed to start bot:', error);
      alert('Failed to start bot.');
    }
  };

  const stopBot = async () => {
    try {
      const response = await fetch('/api/bot/stop', { method: 'POST' });
      const data = await response.json();
      if (response.ok) {
        alert('Bot stopped successfully!');
      } else {
        alert(`Error stopping bot: ${data.error}`);
      }
    } catch (error) {
      alert('An unexpected error occurred while stopping the bot.');
    }
  };

  const testEvent = async (eventType) => {
    try {
      const response = await fetch('/api/test-event', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ eventType }),
      });

      if (!response.ok) {
        const error = await response.json();
        throw new Error(error.error || 'Unknown error occurred');
      }

      const data = await response.json();
      console.log(`Test event '${eventType}' triggered:`, data.message);
    } catch (error) {
      console.error(`Failed to trigger test event '${eventType}':`, error);
      alert(`Failed to trigger test event '${eventType}'.`);
    }
  };

  if (loading) return <p>Loading...</p>;

  if (!settings) return <p>Error loading settings.</p>;

  return (
    <div className="dashboard-container">
      <div className="sidebar">
        <h2>Dashboard</h2>
        <ul>
          <li
            className={activeTab === 'home' ? 'active' : ''}
            onClick={() => handleTabChange('home')}
          >
            Home
          </li>
          <li
            className={activeTab === 'settings' ? 'active' : ''}
            onClick={() => handleTabChange('settings')}
          >
            Bot Settings
          </li>
        </ul>
      </div>
      <div className="content">
        {activeTab === 'home' && (
          <div className="home-tab">
            <h3>Welcome, {user ? user.displayName : 'User'}</h3>
            <p>Status: {settings.botEnabled ? 'Enabled' : 'Disabled'}</p>
            <button onClick={toggleBotEnabled}>
              {settings.botEnabled ? 'Disable Bot' : 'Enable Bot'}
            </button>
            <button onClick={startBot}>Start Bot</button>
            <button onClick={stopBot}>Stop Bot</button>
            <h3>Test Events</h3>
            <button onClick={() => testEvent('follow')}>Test Follow</button>
            <button onClick={() => testEvent('subscription')}>Test Subscription</button>
            <button onClick={() => testEvent('cheer')}>Test Cheer</button>
            <button onClick={() => testEvent('raid')}>Test Raid</button>
            <button onClick={() => testEvent('streamOnline')}>Test Stream Online</button>
          </div>
        )}
        {activeTab === 'settings' && (
          <div className="settings-tab">
            <h3>Bot Personality</h3>
            <textarea
              value={settings.personalityPrompt || ''}
              onChange={(e) =>
                setSettings((prev) => ({
                  ...prev,
                  personalityPrompt: e.target.value,
                }))
              }
              rows="4"
              placeholder="Enter bot's personality prompt"
            />
            <h3>Event Settings</h3>
            <label>
              <input
                type="checkbox"
                checked={settings.respondToFollows || false}
                onChange={() =>
                  setSettings((prev) => ({
                    ...prev,
                    respondToFollows: !prev.respondToFollows,
                  }))
                }
              />
              Respond to Follows
            </label>
            <label>
              <input
                type="checkbox"
                checked={settings.respondToSubscriptions || false}
                onChange={() =>
                  setSettings((prev) => ({
                    ...prev,
                    respondToSubscriptions: !prev.respondToSubscriptions,
                  }))
                }
              />
              Respond to Subscriptions
            </label>
            <button onClick={saveSettings}>Save Settings</button>
          </div>
        )}
      </div>
    </div>
  );
}

export default Dashboard;
